<template>
    <div class="home">
      <div class="container">
        <Top :activeTitle="activeTitle" :list="list" />
        <div class="protocol">
            <div class="protocol_detail" v-html="privacy.detail">
            </div>
        </div>
      </div>
      <FooterNav  :activeIndex="active"/>
      <FooterText />
    </div>
      
      
      
  </template>
  
  <script>
    import FooterNav from "../components/FooterNav.vue";
    import FooterText from "../components/FooterText.vue";
    import Top from "../components/Top.vue";

  export default {
    components: {
        FooterNav,
        FooterText,
        Top,

    },
    data() {  
      return {
        active: 3,
        activeTitle:2,
        privacy:'',
        type:1,
        list:[
          {name:'ปัญหาที่พบบ่อย',url:'/CommonProblem'},
          {name:'ติดต่อเจ้าหน้าที่',url:'/Connect'},
          {name:'นโยบายความเป็นส่วนตัว',url:'/Privacy'},
          {name:'ข้อตกลงการใช้บริการ',url:'/CyberSecurity'},
        ]
  
      };
    },
    computed: {

    },
    mounted() {
  
    },
    created() {    
      this.getPrivacy()
    },
    methods:{
      getPrivacy(){
          this.service.post('Home/getArticle',{type:this.type})
          .then((res) => {
              this.privacy = res.data.data
          }) 
      },
    }
  };
  </script>
  
  <style scoped lang="less">
    .protocol{
      margin-top: 40px;
      margin-bottom: 30px;
      .protocol_detail{
        width: 660px;
        padding: 15px;
        background-color: #FFFFFF;
        border-radius: 15px;
        min-height: 1000px;
      }
    }

  </style>
  
  